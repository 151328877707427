import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Link,
  Box,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import React from 'react';
import { useCookies } from 'react-cookie';

interface GDPRCookieBannerProps {}

const GDPRCookieBanner = ({}: GDPRCookieBannerProps) => {
  const [cookies, setCookie] = useCookies(['hide-gdpr-cookie-banner']);

  const handleClose = () => {
    const today = new Date();

    setCookie('hide-gdpr-cookie-banner', true, {
      expires: new Date(today.setFullYear(today.getFullYear() + 1)),
    });
  };

  return Boolean(cookies['hide-gdpr-cookie-banner']) === true ? (
    <></>
  ) : (
    <Dialog open={true} maxWidth="md" scroll="body">
      <DialogTitle id="simple-dialog-title">Polityka prywatności</DialogTitle>
      <DialogContent>
        <Box>
          <h3>Cześć!</h3>
          <strong>
            Cieszymy się, że odwiedzasz naszą stronę i dziękujemy za
            zainteresowanie tematem ochrony zwierząt.{' '}
          </strong>
          Zgodnie z prawem mamy obowiązek poinformować Cię o możliwym
          przetwarzaniu danych osobowych przez pliki cookies przechowywane na
          Twoim urządzeniu pozostawianych w czasie korzystania z naszych stron
          internetowych lub serwisów.{' '}
          <strong>
            Jako organizacja pozarządowa traktujemy ochronę Twojej prywatności
            priorytetowo. Przepraszamy za niedogodności z tym związane!
          </strong>
        </Box>
        <Box my={3}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              Informacje o plikach cookie{' '}
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <p>
                  Masz możliwość samodzielnej zmiany ustawień dotyczących
                  cookies w swojej przeglądarce internetowej. Jeśli nie wyrażasz
                  zgody na przetwarzanie danych osobowych przez pliki cookies
                  przechowywane na Twoim urządzeniu, prosimy Cię o zmianę
                  ustawień w przeglądarce. W przeciwnym razie nie będziesz
                  mógł/a korzystać z serwisów Stowarzyszenia Otwarte Klatki.
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              Administrator danych osobowych
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <p>
                  Administratorem Twoich danych osobowych jest Stowarzyszenie
                  Otwarte Klatki, z siedzibą przy ul. Wyspiańskiego 14/37,
                  60-750 Poznań, wpisane do Krajowego Rejestru Sądowego pod
                  numerem KRS 0000444120 i posługujące się numerem NIP
                  7831695600.
                </p>
                <p>
                  Pliki cookies mogą być odczytywane przez nasze systemy
                  informatyczne, a także przez systemy należące do innych
                  podmiotów, z których usług korzystamy (np. Facebook, Google) -
                  sprawdź listę naszych{' '}
                  <Link
                    href="https://www.otwarteklatki.pl/polityka-prywatnosci#zaufane-podmioty"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Zaufanych Partnerów
                  </Link>
                  .
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              Cel przetwarzania
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <p>
                  Twoje dane są przetwarzane, aby umożliwić Ci sprawne
                  korzystanie ze stron należących do Stowarzyszenia, zapewnić
                  bezpieczeństwo podczas korzystania ze stron Stowarzyszenia,
                  dokonywać analizy ruchu na stronach Stowarzyszenia oraz
                  umożliwić Ci korzystanie z funkcji społecznościowych (takich
                  jak przycisk "Like" od Facebooka).
                </p>
                <p>
                  Przekazujemy informacje na temat Twojego zachowania na stronie
                  Stowarzyszenia dostawcom wykorzystywanych przez nas mediów
                  społecznościowych oraz systemów analitycznych i reklamowych
                  (zobacz listę naszych{' '}
                  <Link
                    href="https://www.otwarteklatki.pl/polityka-prywatnosci#zaufane-podmioty"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Zaufanych Partnerów
                  </Link>
                  ). Spokojnie! Wiele plików cookies ma charakter zanonimizowany
                  — bez dodatkowych informacji, na ich podstawie nie jest
                  możliwa identyfikacja Twojej tożsamości.
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              Wycofanie zgody
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <p>
                  Twoja przeglądarka internetowa domyślnie dopuszcza
                  wykorzystywanie cookies w Twoim urządzeniu, dlatego przy
                  pierwszej wizycie prosimy o wyrażenie zgody na użycie cookies.
                </p>
                <p>
                  W każdej chwili możesz wycofać zgodę na wykorzystywanie
                  cookies - w tym celu zmień ustawienia w przeglądarce
                  internetowej — możesz całkowicie zablokować automatyczną
                  obsługę plików cookies lub żądać powiadomienia o każdorazowym
                  zamieszczeniu cookies w urządzeniu. Ustawienia można zmienić w
                  dowolnej chwili.
                </p>
                <p>
                  Więcej informacji znajduje się w naszej{' '}
                  <Link
                    href="http://otwarteklatki.pl/polityka-prywatnosci"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Polityce Prywatności
                  </Link>
                  .
                </p>
                <p>
                  W przypadku pytań lub wątpliwości związanych z przetwarzaniem
                  i ochroną danych osobowych prosimy o kontakt:{' '}
                  <Link href="iod@otwarteklatki.pl">iod@otwarteklatki.pl</Link>
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box>
          <p>
            <strong>
              Wierzymy, że Twoje odwiedziny na naszej stronie internetowej są
              wyrazem zainteresowania działalnością Otwartych Klatek i troską o
              prawa zwierząt.
            </strong>
          </p>
          <p>
            <strong>
              Cieszymy się, że jesteś z nami i mamy nadzieję, że wspólnie
              będziemy walczyć o lepszy świat dla zwierząt.
            </strong>
          </p>
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={3}>
          <Grid item xs={12} sm className="center-contents center-text">
            Moja przeglądarka jest ustawiona zgodnie z moimi preferencjami.
          </Grid>
          <Grid item xs={12} sm className="right-align-contents">
            <Button onClick={handleClose} variant="contained" color="primary">
              Akceptuję i przechodzę na stronę
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default GDPRCookieBanner;
